import { EncryptDecryptService } from 'app/service/EncryptDecrypt.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FalconService {
    
    // private url: string = "http://localhost:8000/api/";
    // private url: string = 'https://ca-api.fi.tempcloudsite.com/api/';
    private url: string = 'https://ca-api.fi.tempcloudsite.com/api/';

    orgId = localStorage.getItem('orgId');

    constructor(
        private http: HttpClient,
        private encryptDecryptService: EncryptDecryptService
    ) { }

    private subjectName = new Subject<any>();

    sendUpdate(message: string) { //the component that wants to update something, calls this fn
        this.subjectName.next({ text: message }); //next() will feed the value in Subject
    }

    getUpdate(): Observable<any> { //the receiver component calls this function 
        return this.subjectName.asObservable(); //it returns as an observable to which the receiver funtion will subscribe
    }

    loginCommon(data){
        return this.http.post(this.url + "organisationMaster/loginCommon", data);
    }

    save(data){
        return this.http.post(this.url + "organisationMaster/save", data);
    }

    login(data){
        return this.http.post(this.url + "organisationMaster/login", data);
    }

    getIndustryType(){
        var orgId = localStorage.getItem('orgId');
        return this.http.get(this.url + 'industryType/getAll/' + orgId);
    }
    
    getAllIndustryTypeByFilters(search, orderBy, order, offset){
        var orgId = localStorage.getItem('orgId');
        return this.http.get(this.url + 'industryType/getAllByFilters/' + orgId + '/' + search + '/' + orderBy + '/' + order + '/' + offset);
    }

    addIndustryType(data){
        return this.http.post(this.url + 'industryType/save', data);
    }

    editIndustryType(data){
        return this.http.post(this.url + 'industryType/update', data);
    }

    deleteIndustryType(id){
        return this.http.delete(this.url + 'industryType/deleteById/' + id);
    }

    getClient(){
        var orgId = localStorage.getItem('orgId');
        return this.http.get(this.url + 'client/getAll/' + orgId);
    }

    getAllClientsName(){
        var orgId = localStorage.getItem('orgId');
        return this.http.get(this.url + 'client/getAllClientsName/' + orgId);
    }

    getAllClientByFilters(search, orderBy, order, offset){
        var orgId = localStorage.getItem('orgId');
        return this.http.get(this.url + 'client/getAllByFilters/' + orgId + '/' + search + '/' + orderBy + '/' + order + '/' + offset);
    }

    getClientById(id){
        return this.http.get(this.url + 'client/getById/' + id);
    }

    addClient(data){
        return this.http.post(this.url + 'client/save', data);
    }

    editClient(data){
        return this.http.post(this.url + 'client/update', data);
    }

    deleteClient(id){
        return this.http.delete(this.url + 'client/deleteById/' + id);
    }

    getEngineer(){
        var orgId = localStorage.getItem('orgId');
        return this.http.get(this.url + 'engineer/getAll/' + orgId);
    }

    getAllEngineersName(){
        var orgId = localStorage.getItem('orgId');
        return this.http.get(this.url + 'engineer/getAllEngineersName/' + orgId);
    }

    getAllEngineerByFilters(search, orderBy, order, offset){
        var orgId = localStorage.getItem('orgId');
        return this.http.get(this.url + 'engineer/getAllByFilters/' + orgId + '/' + search + '/' + orderBy + '/' + order + '/' + offset);
    }

    addEngineer(data){
        return this.http.post(this.url + 'engineer/save', data);
    }

    editEngineer(data){
        return this.http.post(this.url + 'engineer/update', data);
    }

    deleteEngineer(id){
        return this.http.delete(this.url + 'engineer/deleteById/' + id);
    }

    getProblemOfEngineer(id){
        return this.http.get(this.url + 'engineer/getProblemOfEngineer/' + id);
    }

    getProblem(){
        var orgId = localStorage.getItem('orgId');
        return this.http.get(this.url + 'problem/getAll/' + orgId);
    }

    getAllProblemByFilters(search, orderBy, order, offset){
        var orgId = localStorage.getItem('orgId');
        return this.http.get(this.url + 'problem/getAllByFilters/' + orgId + '/' + search + '/' + orderBy + '/' + order + '/' + offset);
    }

    addProblem(data){
        return this.http.post(this.url + 'problem/save', data);
    }

    editProblem(data){
        return this.http.post(this.url + 'problem/update', data);
    }

    deleteProblem(id){
        return this.http.delete(this.url + 'problem/deleteById/' + id);
    }

    getUnassignedTicket(search, orderBy, order, offset){
        var orgId = localStorage.getItem('orgId');
        return this.http.get(this.url + 'ticket/getUnassignedTicket/' + orgId + '/' + search + '/' + orderBy + '/' + order + '/' + offset);
    }

    getAssignedTicket(search, orderBy, order, offset){
        var orgId = localStorage.getItem('orgId');
        return this.http.get(this.url + 'ticket/getAssignedTicket/' + orgId + '/' + search + '/' + orderBy + '/' + order + '/' + offset);
    }

    getAppliedForCompletionTickets(search, orderBy, order, offset){
        var orgId = localStorage.getItem('orgId');
        return this.http.get(this.url + 'ticket/getAppliedForCompletionTickets/' + orgId + '/' + search + '/' + orderBy + '/' + order + '/' + offset);
    }

    getCompletedTicket(search, orderBy, order, offset){
        var orgId = localStorage.getItem('orgId');
        return this.http.get(this.url + 'ticket/getCompletedTicket/' + orgId + '/' + search + '/' + orderBy + '/' + order + '/' + offset);
    }

    getAllTicket(search, orderBy, order, offset){
        var orgId = localStorage.getItem('orgId');
        return this.http.get(this.url + 'ticket/getAll/' + orgId + '/' + search + '/' + orderBy + '/' + order + '/' + offset);
    }

    getAssignedTicketByEngineer(search, orderBy, order, offset){
        var userId = localStorage.getItem('userId');
        return this.http.get(this.url + 'ticket/getAssignedTicketByEngineer/' + userId + '/' + search + '/' + orderBy + '/' + order + '/' + offset);
    }

    getAppiedForCompletionTicketByEngineer(search, orderBy, order, offset){
        var userId = localStorage.getItem('userId');
        return this.http.get(this.url + 'ticket/getAppiedForCompletionTicketByEngineer/' + userId + '/' + search + '/' + orderBy + '/' + order + '/' + offset);
    }

    getCompletedTicketByEngineer(search, orderBy, order, offset){
        var userId = localStorage.getItem('userId');
        return this.http.get(this.url + 'ticket/getCompletedTicketByEngineer/' + userId + '/' + search + '/' + orderBy + '/' + order + '/' + offset);
    }

    getAllTicketByEngineer(search, orderBy, order, offset){
        var userId = localStorage.getItem('userId');
        return this.http.get(this.url + 'ticket/getAllTicketByEngineer/' + userId + '/' + search + '/' + orderBy + '/' + order + '/' + offset);
    }

    getTicket(cid){
        var orgId = localStorage.getItem('orgId');
        return this.http.get(this.url + 'ticket/getByClient/' + orgId + '/' + cid);
    }

    getTicketById(id){
        return this.http.get(this.url + 'ticket/getById/' + id);
    }

    addTicket(data){
        return this.http.post(this.url + 'ticket/save', data);
    }

    editTicket(data){
        return this.http.post(this.url + 'ticket/update', data);
    }

    editTicketByColumn(data){
        return this.http.post(this.url + 'ticket/editTicketByColumn', data);
    }

    deleteTicket(id){
        return this.http.delete(this.url + 'ticket/deleteById/' + id);
    }

    assignEngineer(data){
        return this.http.post(this.url + 'ticket/assignEngineer', data);
    }

    changeStatus(data){
        return this.http.post(this.url + 'ticket/changeStatus', data);
    }

    assigningRecommendationsEngineer(areaid, problemid){
        var orgId = localStorage.getItem('orgId');
        return this.http.get(this.url + 'ticket/assigningRecommendationsEngineer/' + orgId + '/' + areaid + '/' + problemid);
    }

    getAllTicketLogsById(ticketId){
        return this.http.get(this.url + 'ticketLogs/getAllById/' + ticketId);
    }

}
