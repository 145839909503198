import { element } from 'protractor';
import { Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { FalconService } from 'app/service/falcon.service';
import { AddTicketDialog } from '../view-client/tabs/client-tickets/client-tickets.component';
import { DeleteDialog } from '../dialog/delete-dialog/delete-dialog.component';
import * as moment from 'moment';

@Component({
  selector: 'app-complaint',
  templateUrl: './complaint.component.html',
  styleUrls: ['./complaint.component.scss'],
  animations   : fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class ComplaintComponent implements OnInit {
    
    currentDate = moment(Date.now()).utc().format('YYYY-MM-DD HH:mm:ss');

    currentTab = 'All';
    loading: boolean = false;

    assignedTicketsLoading: boolean = true;
    assignedTicketsFailed: boolean = false;
    assignedTickets: any = [];
    assignedTicketsSearch = '';
    assignedTicketsOrderBy = 'tickets.id';
    assignedTicketsOrder = 'desc';
    assignedTicketsOffset = 0;
    assignedTicketsTotalRows: number = 0;
    assignedTicketsInfiniteScrollLoading: boolean = false;
    assignedTicketsIsInfiniteScrollDisabled: boolean = false;

    appliedForCompletionTicketsLoading: boolean = true;
    appliedForCompletionTicketsFailed: boolean = false;
    appliedForCompletionTickets: any = [];
    appliedForCompletionTicketsSearch = '';
    appliedForCompletionTicketsOrderBy = 'tickets.id';
    appliedForCompletionTicketsOrder = 'desc';
    appliedForCompletionTicketsOffset = 0;
    appliedForCompletionTicketsTotalRows: number = 0;
    appliedForCompletionTicketsInfiniteScrollLoading: boolean = false;
    appliedForCompletionTicketsIsInfiniteScrollDisabled: boolean = false;

    completedTicketsLoading: boolean = true;
    completedTicketsFailed: boolean = false;
    completedTickets: any = [];
    completedTicketsSearch = '';
    completedTicketsOrderBy = 'tickets.id';
    completedTicketsOrder = 'desc';
    completedTicketsOffset = 0;
    completedTicketsTotalRows: number = 0;
    completedTicketsInfiniteScrollLoading: boolean = false;
    completedTicketsIsInfiniteScrollDisabled: boolean = false;

    allTicketsLoading: boolean = true;
    allTicketsFailed: boolean = false;
    allTickets: any = [];
    allTicketsSearch = '';
    allTicketsOrderBy = 'tickets.id';
    allTicketsOrder = 'desc';
    allTicketsOffset = 0;
    allTicketsTotalRows: number = 0;
    allTicketsInfiniteScrollLoading: boolean = false;
    allTicketsIsInfiniteScrollDisabled: boolean = false;

    constructor(
        private titleService: Title,
        private falconService: FalconService,
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private router: Router
    ) {
            this.titleService.setTitle("Falcon - Ticket");
    }

    ngOnInit() {
        if(localStorage.getItem('type') == 'employee'){
            this.router.navigate(['engineer/ticket']);
        }
        this.getAllTickets();
        this.getAssignedTickets();
        this.getAppliedForCompletionTickets();
        this.getCompletedTickets();
    }

    //Common methods
    onTabChange(event){
        if(event.substring(0,3) == 'All'){
            this.currentTab == 'All';
        }else if(event.substring(0,3) == 'Ass'){
            this.currentTab == 'Ass';
        }else if(event.substring(0,3) == 'App'){
            this.currentTab == 'App';
        }else if(event.substring(0,3) == 'Com'){
            this.currentTab == 'Com';
        }
        this.currentTab = event.substring(0,3);
    }

    openTicketDetail(id){
        this.router.navigateByUrl('ticket/' + id);
    }

    openTicketAddDialog(type, id, ticket, clientId) {
        const dialogRef = this.dialog.open(AddTicketDialog, {
            disableClose: true, 
            autoFocus: false,
            data: {
                type: type,
                id: id,
                ticket: ticket,
                client_id: clientId
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result != 0){
                this.assignedTicketsLoading = true;
                this.assignedTickets.length = 0;
                this.assignedTicketsOffset = 0;
                this.assignedTicketsInfiniteScrollLoading = false;
                this.assignedTicketsIsInfiniteScrollDisabled = false;
                this.appliedForCompletionTicketsLoading = true;
                this.appliedForCompletionTickets.length = 0;
                this.appliedForCompletionTicketsOffset = 0;
                this.appliedForCompletionTicketsInfiniteScrollLoading = false;
                this.appliedForCompletionTicketsIsInfiniteScrollDisabled = false;
                this.completedTicketsLoading = true;
                this.completedTickets.length = 0;
                this.completedTicketsOffset = 0;
                this.completedTicketsInfiniteScrollLoading = false;
                this.completedTicketsIsInfiniteScrollDisabled = false;
                this.allTicketsLoading = true;
                this.allTickets.length = 0;
                this.allTicketsOffset = 0;
                this.allTicketsInfiniteScrollLoading = false;
                this.allTicketsIsInfiniteScrollDisabled = false;
                this.getAllTickets();
                this.getAssignedTickets();
                this.getAppliedForCompletionTickets();
                this.getCompletedTickets();
            }
        });
    }

    deleteTicket(id, index){
        const dialogRef = this.dialog.open(DeleteDialog, {
            disableClose: true, 
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result == 1){
                this.loading = true;
                this.falconService.deleteTicket(id)
                .subscribe((result: any) => {
                    this.openSnackBar('Ticket Deleted');
                     this.assignedTickets.forEach((element,index)=>{
                        if(element.id == id) this.assignedTickets.splice(index,1);
                    });
                     this.completedTickets.forEach((element,index)=>{
                        if(element.id == id) this.completedTickets.splice(index,1);
                    });
                    this.allTickets.forEach((element,index)=>{
                        if(element.id == id) this.allTickets.splice(index,1);
                    });
                    this.loading = false;
                },
                (error) => {
                    this.openSnackBar('Failed to Delete');
                    this.loading = false;
                });
            }
        });
    }

    ticketComplete(id){
        this.loading = true;
        let data = {
            'id': id,
            'status': 3
        };
        this.falconService.changeStatus(data)
        .subscribe((result) => {
            this.assignedTickets.forEach((element,index)=>{
                if(element.id == id) this.assignedTickets.splice(index,1);
            });
            this.allTickets.forEach((element,index)=>{
                if(element.id == id) 
                {
                    this.allTickets[index].status = 3;
                }
            });
            this.completedTicketsLoading = true;
            this.completedTickets.length = 0;
            this.completedTicketsOffset = 0;
            this.completedTicketsInfiniteScrollLoading = false;
            this.completedTicketsIsInfiniteScrollDisabled = false;
            this.getCompletedTickets();
            this.openSnackBar('Status updated successfuly');
            this.loading = false;
        },
        (error) => {
            this.loading = false;
            this.openSnackBar('Failed to update successfuly');
        });
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, 'Close', {
          duration: 3000,
        });
    }

    //Applied for Completion tickets methods
    refreshAppliedForCompletionTickets(){
        this.appliedForCompletionTicketsLoading = true;
        this.getAppliedForCompletionTickets();
    }

    getAppliedForCompletionTickets(){
        var s;
        if(this.appliedForCompletionTicketsSearch == ''){
            s = 'null';
            this.appliedForCompletionTicketsSearch = null;
        }
        else{
            s = this.appliedForCompletionTicketsSearch;
        }
        this.appliedForCompletionTicketsFailed = false;
        this.falconService.getAppliedForCompletionTickets(s, this.appliedForCompletionTicketsOrderBy, this.appliedForCompletionTicketsOrder, this.appliedForCompletionTicketsOffset)
        .subscribe((result: any) => {
            result.data.forEach(element => {
                this.appliedForCompletionTickets.push(element);
            });
            this.appliedForCompletionTicketsTotalRows = result.totalRows;
            this.appliedForCompletionTicketsOffset += result.data.length;
            this.appliedForCompletionTicketsIsInfiniteScrollDisabled = false;
            if(this.appliedForCompletionTicketsOffset >= result.totalRows){
                this.appliedForCompletionTicketsIsInfiniteScrollDisabled = true;
            }
            this.appliedForCompletionTicketsLoading = false;
            this.appliedForCompletionTicketsInfiniteScrollLoading = false;
        },
        (error) => {
            this.openSnackBar('Failed to load Applied for Completion Tickets');
            this.appliedForCompletionTicketsLoading = false;
            this.appliedForCompletionTicketsFailed = true;
            this.appliedForCompletionTicketsInfiniteScrollLoading = false;
        });
    }

    appliedForCompletionTicketResetFilters(){
        if(!this.appliedForCompletionTicketsLoading && !this.appliedForCompletionTicketsInfiniteScrollLoading){
            this.appliedForCompletionTicketsLoading = true;
            this.appliedForCompletionTickets.length = 0;
            this.appliedForCompletionTicketsSearch = '';
            this.appliedForCompletionTicketsOrderBy = 'tickets.id';
            this.appliedForCompletionTicketsOrder = 'desc';
            this.appliedForCompletionTicketsOffset = null;
            this.appliedForCompletionTicketsTotalRows = 0;
            this.getAppliedForCompletionTickets();
        }
        else{
            this.openSnackBar("Can't reset while loading");
        }
    }

    appliedForCompletionTicketOnSearch(search){
        if(!this.appliedForCompletionTicketsLoading && !this.appliedForCompletionTicketsInfiniteScrollLoading){
            this.appliedForCompletionTicketsLoading = true;
            this.appliedForCompletionTickets.length = 0;
            this.appliedForCompletionTicketsSearch = search;
            this.appliedForCompletionTicketsOffset = null;
            this.appliedForCompletionTicketsTotalRows = 0;
            this.getAppliedForCompletionTickets();
        }
        else{
            this.openSnackBar("Can't search while loading");
        }
    }

    appliedForCompletionTicketOnColumnSort(columnName){
        if(!this.appliedForCompletionTicketsLoading && !this.appliedForCompletionTicketsInfiniteScrollLoading){
            this.appliedForCompletionTicketsLoading = true;
            this.appliedForCompletionTickets.length = 0;
            this.appliedForCompletionTicketsOffset = null;
            this.appliedForCompletionTicketsTotalRows = 0;
            if(this.appliedForCompletionTicketsOrderBy == columnName){
                if(this.appliedForCompletionTicketsOrder == 'asc'){
                    this.appliedForCompletionTicketsOrder = 'desc';
                }
                else{
                    this.appliedForCompletionTicketsOrder = 'asc';
                }
            }
            else{
                this.appliedForCompletionTicketsOrder = 'asc';
            }
            this.appliedForCompletionTicketsOrderBy = columnName;
            this.getAppliedForCompletionTickets();
        }
        else{
            this.openSnackBar("Can't sort while loading");
        }
    }

    appliedForCompletionTicketOnScroll(){
        if(this.appliedForCompletionTicketsIsInfiniteScrollDisabled == false){
            this.appliedForCompletionTicketsIsInfiniteScrollDisabled = true;
            this.appliedForCompletionTicketsInfiniteScrollLoading = true;
            this.getAppliedForCompletionTickets();
        }
    }

    //Assigned tickets methods
    refreshAssignedTickets(){
        this.assignedTicketsLoading = true;
        this.getAssignedTickets();
    }

    getAssignedTickets(){
        var s;
        if(this.assignedTicketsSearch == ''){
            s = 'null';
            this.assignedTicketsSearch = null;
        }
        else{
            s = this.assignedTicketsSearch;
        }
        this.assignedTicketsFailed = false;
        this.falconService.getAssignedTicket(s, this.assignedTicketsOrderBy, this.assignedTicketsOrder, this.assignedTicketsOffset)
        .subscribe((result: any) => {
            result.data.forEach(element => {
                this.assignedTickets.push(element);
            });
            this.assignedTicketsTotalRows = result.totalRows;
            this.assignedTicketsOffset += result.data.length;
            this.assignedTicketsIsInfiniteScrollDisabled = false;
            if(this.assignedTicketsOffset >= result.totalRows){
                this.assignedTicketsIsInfiniteScrollDisabled = true;
            }
            this.assignedTicketsLoading = false;
            this.assignedTicketsInfiniteScrollLoading = false;
        },
        (error) => {
            this.openSnackBar('Failed to load Assigned Tickets');
            this.assignedTicketsLoading = false;
            this.assignedTicketsFailed = true;
            this.assignedTicketsInfiniteScrollLoading = false;
        });
    }

    assignedTicketResetFilters(){
        if(!this.assignedTicketsLoading && !this.assignedTicketsInfiniteScrollLoading){
            this.assignedTicketsLoading = true;
            this.assignedTickets.length = 0;
            this.assignedTicketsSearch = '';
            this.assignedTicketsOrderBy = 'tickets.id';
            this.assignedTicketsOrder = 'desc';
            this.assignedTicketsOffset = null;
            this.assignedTicketsTotalRows = 0;
            this.getAssignedTickets();
        }
        else{
            this.openSnackBar("Can't reset while loading");
        }
    }

    assignedTicketOnSearch(search){
        if(!this.assignedTicketsLoading && !this.assignedTicketsInfiniteScrollLoading){
            this.assignedTicketsLoading = true;
            this.assignedTickets.length = 0;
            this.assignedTicketsSearch = search;
            this.assignedTicketsOffset = null;
            this.assignedTicketsTotalRows = 0;
            this.getAssignedTickets();
        }
        else{
            this.openSnackBar("Can't search while loading");
        }
    }

    assignedTicketOnColumnSort(columnName){
        if(!this.assignedTicketsLoading && !this.assignedTicketsInfiniteScrollLoading){
            this.assignedTicketsLoading = true;
            this.assignedTickets.length = 0;
            this.assignedTicketsOffset = null;
            this.assignedTicketsTotalRows = 0;
            if(this.assignedTicketsOrderBy == columnName){
                if(this.assignedTicketsOrder == 'asc'){
                    this.assignedTicketsOrder = 'desc';
                }
                else{
                    this.assignedTicketsOrder = 'asc';
                }
            }
            else{
                this.assignedTicketsOrder = 'asc';
            }
            this.assignedTicketsOrderBy = columnName;
            this.getAssignedTickets();
        }
        else{
            this.openSnackBar("Can't sort while loading");
        }
    }

    assignedTicketOnScroll(){
        if(this.assignedTicketsIsInfiniteScrollDisabled == false){
            this.assignedTicketsIsInfiniteScrollDisabled = true;
            this.assignedTicketsInfiniteScrollLoading = true;
            this.getAssignedTickets();
        }
    }

    //Completed tickets methods
    refreshCompletedTickets(){
        this.completedTicketsLoading = true;
        this.getCompletedTickets();
    }

    getCompletedTickets(){
        var s;
        if(this.completedTicketsSearch == ''){
            s = 'null';
            this.completedTicketsSearch = null;
        }
        else{
            s = this.completedTicketsSearch;
        }
        this.completedTicketsFailed = false;
        this.falconService.getCompletedTicket(s, this.completedTicketsOrderBy, this.completedTicketsOrder, this.completedTicketsOffset)
        .subscribe((result: any) => {
            result.data.forEach(element => {
                this.completedTickets.push(element);
            });
            this.completedTicketsTotalRows = result.totalRows;
            this.completedTicketsOffset += result.data.length;
            this.completedTicketsIsInfiniteScrollDisabled = false;
            if(this.completedTicketsOffset >= result.totalRows){
                this.completedTicketsIsInfiniteScrollDisabled = true;
            }
            this.completedTicketsLoading = false;
            this.completedTicketsInfiniteScrollLoading = false;
        },
        (error) => {
            this.openSnackBar('Failed to load Completed Tickets');
            this.completedTicketsLoading = false;
            this.completedTicketsFailed = true;
            this.completedTicketsInfiniteScrollLoading = false;
        });
    }

    completedTicketResetFilters(){
        if(!this.completedTicketsLoading && !this.completedTicketsInfiniteScrollLoading){
            this.completedTicketsLoading = true;
            this.completedTickets.length = 0;
            this.completedTicketsSearch = '';
            this.completedTicketsOrderBy = 'tickets.id';
            this.completedTicketsOrder = 'desc';
            this.completedTicketsOffset = null;
            this.completedTicketsTotalRows = 0;
            this.getCompletedTickets();
        }
        else{
            this.openSnackBar("Can't reset while loading");
        }
    }

    completedTicketOnSearch(search){
        if(!this.completedTicketsLoading && !this.completedTicketsInfiniteScrollLoading){
            this.completedTicketsLoading = true;
            this.completedTickets.length = 0;
            this.completedTicketsSearch = search;
            this.completedTicketsOffset = null;
            this.completedTicketsTotalRows = 0;
            this.getCompletedTickets();
        }
        else{
            this.openSnackBar("Can't search while loading");
        }
    }

    completedTicketOnColumnSort(columnName){
        if(!this.completedTicketsLoading && !this.completedTicketsInfiniteScrollLoading){
            this.completedTicketsLoading = true;
            this.completedTickets.length = 0;
            this.completedTicketsOffset = null;
            this.completedTicketsTotalRows = 0;
            if(this.completedTicketsOrderBy == columnName){
                if(this.completedTicketsOrder == 'asc'){
                    this.completedTicketsOrder = 'desc';
                }
                else{
                    this.completedTicketsOrder = 'asc';
                }
            }
            else{
                this.completedTicketsOrder = 'asc';
            }
            this.completedTicketsOrderBy = columnName;
            this.getCompletedTickets();
        }
        else{
            this.openSnackBar("Can't sort while loading");
        }
    }

    completedTicketOnScroll(){
        if(this.completedTicketsIsInfiniteScrollDisabled == false){
            this.completedTicketsIsInfiniteScrollDisabled = true;
            this.completedTicketsInfiniteScrollLoading = true;
            this.getCompletedTickets();
        }
    }

    //All tickets methods
    refreshAllTickets(){
        this.allTicketsLoading = true;
        this.getAllTickets();
    }
    
    getAllTickets(){
        var s;
        if(this.allTicketsSearch == ''){
            s = 'null';
            this.allTicketsSearch = null;
        }
        else{
            s = this.allTicketsSearch;
        }
        this.allTicketsFailed = false;
        this.falconService.getAllTicket(s, this.allTicketsOrderBy, this.allTicketsOrder, this.allTicketsOffset)
        .subscribe((result: any) => {
            result.data.forEach(element => {
                this.allTickets.push(element);
            });
            this.allTicketsTotalRows = result.totalRows;
            this.allTicketsOffset += result.data.length;
            this.allTicketsIsInfiniteScrollDisabled = false;
            if(this.allTicketsOffset >= result.totalRows){
                this.allTicketsIsInfiniteScrollDisabled = true;
            }
            this.allTicketsLoading = false;
            this.allTicketsInfiniteScrollLoading = false;
        },
        (error) => {
            this.openSnackBar('Failed to load All Tickets');
            this.allTicketsLoading = false;
            this.allTicketsFailed = true;
            this.allTicketsInfiniteScrollLoading = false;
        });
    }

    allTicketResetFilters(){
        if(!this.allTicketsLoading && !this.allTicketsInfiniteScrollLoading){
            this.allTicketsLoading = true;
            this.allTickets.length = 0;
            this.allTicketsSearch = '';
            this.allTicketsOrderBy = 'tickets.id';
            this.allTicketsOrder = 'desc';
            this.allTicketsOffset = null;
            this.allTicketsTotalRows = 0;
            this.getAllTickets();
        }
        else{
            this.openSnackBar("Can't reset while loading");
        }
    }

    allTicketOnSearch(search){
        if(!this.allTicketsLoading && !this.allTicketsInfiniteScrollLoading){
            this.allTicketsLoading = true;
            this.allTickets.length = 0;
            this.allTicketsSearch = search;
            this.allTicketsOffset = null;
            this.allTicketsTotalRows = 0;
            this.getAllTickets();
        }
        else{
            this.openSnackBar("Can't search while loading");
        }
    }

    allTicketOnColumnSort(columnName){
        if(!this.allTicketsLoading && !this.allTicketsInfiniteScrollLoading){
            this.allTicketsLoading = true;
            this.allTickets.length = 0;
            this.allTicketsOffset = null;
            this.allTicketsTotalRows = 0;
            if(this.allTicketsOrderBy == columnName){
                if(this.allTicketsOrder == 'asc'){
                    this.allTicketsOrder = 'desc';
                }
                else{
                    this.allTicketsOrder = 'asc';
                }
            }
            else{
                this.allTicketsOrder = 'asc';
            }
            this.allTicketsOrderBy = columnName;
            this.getAllTickets();
        }
        else{
            this.openSnackBar("Can't sort while loading");
        }
    }

    allTicketOnScroll(){
        if(this.allTicketsIsInfiniteScrollDisabled == false){
            this.allTicketsIsInfiniteScrollDisabled = true;
            this.allTicketsInfiniteScrollLoading = true;
            this.getAllTickets();
        }
    }

}
