import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { FalconService } from 'app/service/falcon.service';
import { DeleteDialog } from '../dialog/delete-dialog/delete-dialog.component';

export interface DialogData {
    type: string;
    id: number;
    industry_type_name: string;
}

@Component({
  selector: 'app-industry-type',
  templateUrl: './industry-type.component.html',
  styleUrls: ['./industry-type.component.scss'],
  animations   : fuseAnimations,
  encapsulation: ViewEncapsulation.None
})
export class IndustryTypeComponent implements OnInit {

    dataLoading: boolean = true;
    loading: boolean = false;
    failed: boolean = false;
    
    industryTypes: any = [];
    search = '';
    orderBy = 'industry_type_name';
    order = 'asc';
    offset = 0;
    totalRows: number;
    infiniteScrollLoading: boolean = false;
    isInfiniteScrollDisabled: boolean = false;

    constructor(
        private titleService: Title,
        private falconService: FalconService,
        public dialog: MatDialog,
        private snackBar: MatSnackBar
    ){ 
        this.titleService.setTitle("Falcon - Tasks");
    }

    ngOnInit(){
        this.getIndustryType();
    }

    refresh(){
        this.infiniteScrollLoading = true;
        this.dataLoading = true;
        this.getIndustryType();
    }

    getIndustryType(){
        this.failed = false;
        var s;
        if(this.search == ''){
            s = 'null';
        }
        else{
            s = this.search;
        }
        this.falconService.getAllIndustryTypeByFilters(s, this.orderBy, this.order, this.offset)
        .subscribe((result: any) => {
            result.data.forEach(element => {
                this.industryTypes.push(element);
            });
            this.totalRows = result.totalRows;
            this.offset += result.data.length;
            this.isInfiniteScrollDisabled = false;
            if(this.offset >= result.totalRows){
                this.isInfiniteScrollDisabled = true;
            }
            this.infiniteScrollLoading = false;
            this.dataLoading = false;
            this.snackBar.dismiss();
        },
        (error) => {
            this.snackBar.dismiss();
            this.openSnackBar('Failed to load', 'Close', 3000, 'center', 'bottom');
            this.dataLoading = false;
            this.failed = true;
            this.infiniteScrollLoading = false;
        });
    }

    openIndustryTypeAddDialog(type, id, industry_type_name) {
        const dialogRef = this.dialog.open(AddIndustryTypeDialog, {
            disableClose: true, 
            autoFocus: false,
            data: {
                type: type,
                id: id,
                industry_type_name: industry_type_name
            }
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if(result != 0){
                this.dataLoading = true;
                this.offset = 0;
                this.infiniteScrollLoading = false;
                this.isInfiniteScrollDisabled = false;
                this.industryTypes = [];
                this.getIndustryType();
            }
        });
    }

    deleteIndustryType(id, index){
        const dialogRef = this.dialog.open(DeleteDialog, {
            disableClose: true, 
            autoFocus: false
        });
    
        dialogRef.afterClosed().subscribe(result => {
            if(result == 1){
                this.loading = true;
                this.falconService.deleteIndustryType(id)
                .subscribe((result: any) => {
                    this.openSnackBar('Industry Type Deleted', 'Close', 3000, 'center', 'bottom');
                    this.industryTypes.splice(index, 1);
                    this.offset--;
                    this.totalRows--;
                    this.loading = false;
                },
                (error) => {
                    this.openSnackBar('Failed to Delete', 'Close', 3000, 'center', 'bottom');
                    this.loading = false;
                });
            }
        });
    }

    resetFilters(){
        if(!this.dataLoading && !this.infiniteScrollLoading){
            this.dataLoading = true;
            this.industryTypes = [];
            this.search = '';
            this.orderBy = 'industry_type_name';
            this.order = 'asc';
            this.offset = null;
            this.totalRows = null;
            this.getIndustryType();
        }
        else{
            this.openSnackBar("Can't reset while loading", 'Close', 3000, 'center', 'bottom');
        }
    }

    onSearch(search){
        if(!this.dataLoading && !this.infiniteScrollLoading){
            this.dataLoading = true;
            this.industryTypes = [];
            this.search = search;
            this.offset = null;
            this.totalRows = null;
            this.getIndustryType();
        }
        else{
            this.openSnackBar("Can't search while loading", 'Close', 3000, 'center', 'bottom');
        }
    }

    onColumnSort(columnName){
        if(!this.dataLoading && !this.infiniteScrollLoading){
            this.dataLoading = true;
            this.industryTypes = [];
            this.offset = null;
            this.totalRows = null;
            if(this.orderBy == columnName){
                if(this.order == 'asc'){
                    this.order = 'desc';
                }
                else{
                    this.order = 'asc';
                }
            }
            else{
                this.order = 'asc';
            }
            this.orderBy = columnName;
            this.getIndustryType();
        }
        else{
            this.openSnackBar("Can't sort while loading", 'Close', 3000, 'center', 'bottom');
        }
    }

    onScroll(){
        if(this.isInfiniteScrollDisabled == false){
            this.isInfiniteScrollDisabled = true;
            this.infiniteScrollLoading = true;
            this.getIndustryType();
        }
    }

    openSnackBar(message, close, duration, horizontalPosition: MatSnackBarHorizontalPosition, verticalPosition: MatSnackBarVerticalPosition) {
        this.snackBar.open(message, close, {
            duration: duration,
            horizontalPosition: horizontalPosition,
            verticalPosition: verticalPosition,
        });
    }

}

@Component({
    selector: 'add-industry-type-dialog',
    templateUrl: 'add-industry-type-dialog.html'
  })
  export class AddIndustryTypeDialog {

    orgId = localStorage.getItem('orgId');
    industryTypeForm: FormGroup;

    loading: boolean = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public dialogRef: MatDialogRef<AddIndustryTypeDialog>,
        private _formBuilder: FormBuilder,
        private falconService: FalconService,
        private snackBar: MatSnackBar
    ){ }

    ngOnInit(): void
    {
        this.industryTypeForm = this._formBuilder.group({
            industry_type_name: [this.data.industry_type_name, [Validators.required]]
        });
    }

    addIndustryType(){
        this.loading = true;

        var data = {
            id: this.data.id,
            org_id: this.orgId,
            industry_type_name: this.industryTypeForm.get('industry_type_name').value,
            created_by: localStorage.getItem('userId')
        };

        if(this.data.id == 0){
            this.falconService.addIndustryType(data)
            .subscribe((result) => {
                this.openSnackBar('Industry Type successfuly Added');
                this.loading = false;
                this.dialogRef.close();
            },
            (error) => {
                this.openSnackBar('Failed to Add Industry Type');
                this.loading = false;
            });
        }
        else{
            this.falconService.editIndustryType(data)
            .subscribe((result) => {
                this.openSnackBar('Industry Type successfuly Edited');
                this.loading = false;
                this.dialogRef.close();
            },
            (error) => {
                this.openSnackBar('Failed to Edit Industry Type');
                this.loading = false;
            });
        }
    }

    openSnackBar(message: string) {
        this.snackBar.open(message, 'Close', {
          duration: 3000,
        });
    }

  }
